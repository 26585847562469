import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Container, Form, Input, FormFeedback, Label, Spinner, FormGroup, Button } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../../assets/images/logo.png';
import api from "../../../services/api";
import { showToastArray, showToastErrorDefault } from '../../../helpers/generals';
import { encodeImageFileAsURL } from "../../../helpers/filesBase64";
import { MaskCellPhone, MaskCpf } from "../../../helpers/mascarasParaCampos";

const NewGuest = props => {

    const navigate = useNavigate();

    document.title = "Novo cadastro de convidado | Empresários SA";

    const [Loading, setLoading] = useState(false);
    const [FormData, setFormData] = useState({});

    const [EventsData, setEventsData] = useState([]);
    const [BranchesData, setBranchesData] = useState([]);

    const [FileName, setFileName] = useState();
    const [FileB64, setFileB64] = useState();

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            who: FormData.who || '',
            events_id: FormData.events_id || '',
            name: FormData.name || '',
            cpf: FormData.cpf || '',
            email: FormData.email || '',
            cell_phone: FormData.cell_phone || '',
            companie_name: FormData.companie_name || '',
            branches_id: FormData.branches_id || '',
            FileName: FileName || '',
            file: FileB64 || '',
        },
        validationSchema: Yup.object({
            events_id: Yup.string().required("Selecione uma opção."),
            name: Yup.string().required("Digite esse campo."),
            who: Yup.string().required("Digite esse campo."),
            email: Yup.string().required("Digite esse campo."),
            cell_phone: Yup.string().required("Digite esse campo."),
            companie_name: Yup.string().required("Digite esse campo."),
            branches_id: Yup.string().required("Selecione uma opção."),
            FileName: Yup.string().required("Selecione um comprovante."),
        }),
        onSubmit: (values) => {
            setLoading(true);
            api.post('/api/register-guest', values).then(response => {
                return response.data;
            }).then(data => {
                if (data.success == true) {
                    setLoading(false);
                    setFormData({});
                    showToastArray("", "Sucesso", data.message);
                    navigate("/login");
                }
            }).catch(error => {
                if (error) {
                    let message = 'Erro ao enviar o registro!';
                    setLoading(false);
                    if (error.response) {
                        if (error.response.data) message = error.response.data.message;
                    }
                    showToastErrorDefault(message);
                }
            });
        }
    });

    const LoadEvents = async => {
        setEventsData([]);
        api.get('/api/events/active').then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setEventsData(data.data);
                setLoading(false);
            }
        }).catch(error => {
            // console.log(error);
        })
    };

    function LoadBranches() {
        setBranchesData([]);
        api.get('/api/branches/active').then(response => {
            return response.data
        }).then(data => {
            if (data.success === true) {
                setBranchesData(data.data);
            }
        }).catch(error => {
            // console.log(error);
        })
    };

    useEffect(() => {
        LoadBranches();
        LoadEvents();
    }, []);

    async function fileBase64Code(event) {
        event.persist();

        if (!event.target || !event.target.files) {
            return;
        }

        const fileList = event.target.files;
        const latestUploadedFile = fileList.item(fileList.length - 1);

        try {
            const fileContents = await encodeImageFileAsURL(latestUploadedFile);
            let b64 = '';
            let base64result = fileContents.substr(fileContents.indexOf(',') + 1);
            let base64dataType = fileContents.split(';base64,')[0];
            base64dataType = base64dataType.split('/')[1];
            b64 = base64dataType + '##@@nedo##$$' + base64result;
            if (b64) setFileB64(b64);
        } catch (e) {
            // console.log(e);
        }
    };

    return (
        <React.Fragment>
            <Form
                className="form-horizontal"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={8} xl={8}>
                                <Card className="overflow-hidden">
                                    <div className="bg-softbg-soft-primary">
                                        <Row>
                                            <Col xs={12} className="mb-1 mt-5 text-center">
                                                <img src={Logo} alt="" height="40px" />
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <div className="p-2 pt-5">
                                            <div className="mb-3">
                                                <Row>
                                                    <Col sm="12">
                                                        <h4 className="pb-3">Dados do convidado</h4>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="12">
                                                        <Row>
                                                            <Col sm="12">
                                                                <FormGroup floating>
                                                                    <Input
                                                                        name="events_id"
                                                                        type="select"
                                                                        onChange={(event) => { setFormData({ ...FormData, 'events_id': event.target.value }); }}
                                                                        value={FormData.events_id ? FormData.events_id : ''}
                                                                        onBlur={validation.handleBlur}
                                                                        invalid={
                                                                            validation.touched.events_id && validation.errors.events_id ? true : false
                                                                        }
                                                                    >
                                                                        <option value=""> Selecione... </option>
                                                                        {EventsData.map((row, index) => (
                                                                            <option key={index} value={row.id}>{row.name}</option>
                                                                        ))}
                                                                    </Input>
                                                                    {validation.touched.events_id && validation.errors.events_id ? (
                                                                        <FormFeedback type="invalid">{validation.errors.events_id}</FormFeedback>
                                                                    ) : null}
                                                                    <Label>
                                                                        Evento
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm="12">
                                                                <FormGroup floating>
                                                                    <Input
                                                                        name="name"
                                                                        type="text"
                                                                        autoComplete={false}
                                                                        maxLength={255}
                                                                        onChange={(event) => { setFormData({ ...FormData, 'name': event.target.value }); }}
                                                                        value={FormData.name ? FormData.name : ''}
                                                                        onBlur={validation.handleBlur}
                                                                        invalid={
                                                                            validation.touched.name && validation.errors.name ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.name && validation.errors.name ? (
                                                                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                                    ) : null}
                                                                    <Label>
                                                                        Nome completo
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm="12">
                                                                <FormGroup floating>
                                                                    <Input
                                                                        autoComplete={false}
                                                                        name="cpf"
                                                                        type="text"
                                                                        maxLength={255}
                                                                        onChange={(event) => { setFormData({ ...FormData, 'cpf': MaskCpf(event.target.value) }); }}
                                                                        value={FormData.cpf ? FormData.cpf : ''}
                                                                        onBlur={validation.handleBlur}
                                                                        invalid={
                                                                            validation.touched.cpf && validation.errors.cpf ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.cpf && validation.errors.cpf ? (
                                                                        <FormFeedback type="invalid">{validation.errors.cpf}</FormFeedback>
                                                                    ) : null}
                                                                    <Label>
                                                                        CPF
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm="12">
                                                                <FormGroup floating>
                                                                    <Input
                                                                        autoComplete={false}
                                                                        name="email"
                                                                        type="email"
                                                                        maxLength={255}
                                                                        onChange={(event) => { setFormData({ ...FormData, 'email': event.target.value }); }}
                                                                        value={FormData.email ? FormData.email : ''}
                                                                        onBlur={validation.handleBlur}
                                                                        invalid={
                                                                            validation.touched.email && validation.errors.email ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.email && validation.errors.email ? (
                                                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                                    ) : null}
                                                                    <Label>
                                                                        E-mail
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm="12">
                                                                <FormGroup floating>
                                                                    <Input
                                                                        autoComplete={false}
                                                                        name="cell_phone"
                                                                        type="text"
                                                                        maxLength={255}
                                                                        onChange={(event) => { setFormData({ ...FormData, 'cell_phone': MaskCellPhone(event.target.value) }); }}
                                                                        value={FormData.cell_phone ? FormData.cell_phone : ''}
                                                                        onBlur={validation.handleBlur}
                                                                        invalid={
                                                                            validation.touched.cell_phone && validation.errors.cell_phone ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.cell_phone && validation.errors.cell_phone ? (
                                                                        <FormFeedback type="invalid">{validation.errors.cell_phone}</FormFeedback>
                                                                    ) : null}
                                                                    <Label>
                                                                        Celular
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm="12">
                                                                <FormGroup floating>
                                                                    <Input
                                                                        autoComplete={false}
                                                                        name="companie_name"
                                                                        type="text"
                                                                        maxLength={255}
                                                                        onChange={(event) => { setFormData({ ...FormData, 'companie_name': event.target.value }); }}
                                                                        value={FormData.companie_name ? FormData.companie_name : ''}
                                                                        onBlur={validation.handleBlur}
                                                                        invalid={
                                                                            validation.touched.companie_name && validation.errors.companie_name ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.companie_name && validation.errors.companie_name ? (
                                                                        <FormFeedback type="invalid">{validation.errors.companie_name}</FormFeedback>
                                                                    ) : null}
                                                                    <Label>
                                                                        Empresa
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm="12">
                                                                <FormGroup floating>
                                                                    <Input
                                                                        className=""
                                                                        autoComplete={false}
                                                                        name="who"
                                                                        type="text"
                                                                        maxLength={255}
                                                                        onChange={(event) => { setFormData({ ...FormData, 'who': event.target.value }); }}
                                                                        value={FormData.who ? FormData.who : ''}
                                                                        onBlur={validation.handleBlur}
                                                                        invalid={
                                                                            validation.touched.who && validation.errors.who ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.who && validation.errors.who ? (
                                                                        <FormFeedback type="invalid">{validation.errors.who}</FormFeedback>
                                                                    ) : null}
                                                                    <Label>
                                                                        Quem dá nossa equipe está te atendendo?
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg={12}>
                                                                <FormGroup floating>
                                                                    <Input
                                                                        id="branches_id"
                                                                        className="branches_id"
                                                                        name="branches_id"
                                                                        type="select"
                                                                        onChange={(event) => {
                                                                            setFormData({ ...FormData, 'branches_id': event.target.value });
                                                                        }}
                                                                        value={FormData.branches_id ? FormData.branches_id : ''}
                                                                        onBlur={validation.handleBlur}
                                                                        invalid={
                                                                            validation.touched.branches_id && validation.errors.branches_id ? true : false
                                                                        }
                                                                    >
                                                                        {validation.touched.branches_id && validation.errors.branches_id ? (
                                                                            <FormFeedback type="invalid">{validation.errors.branches_id}</FormFeedback>
                                                                        ) : null}
                                                                        <option value=""> Selecione... </option>
                                                                        {BranchesData.map((row, index) => (
                                                                            <option key={index} value={row.id}>{row.name}</option>
                                                                        ))}
                                                                    </Input>
                                                                    <Label>
                                                                        Ramo
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm="12">
                                                                <FormGroup>
                                                                    <Label>
                                                                        Anexar comprovante
                                                                    </Label>
                                                                    <Input
                                                                        className=""
                                                                        autoComplete={false}
                                                                        name="file"
                                                                        type="file"
                                                                        onChange={(event) => {
                                                                            setFileName(event.target.value);
                                                                            fileBase64Code(event);

                                                                        }}
                                                                        value={FileName ? FileName : ''}
                                                                        accept="image/jpeg,image/png,application/pdf"
                                                                        onBlur={validation.handleBlur}
                                                                        invalid={
                                                                            validation.touched.FileName && validation.errors.FileName ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.FileName && validation.errors.FileName ? (
                                                                        <FormFeedback type="invalid">{validation.errors.FileName}</FormFeedback>
                                                                    ) : null}
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3 mb-2">
                                                    <Col sm="12">
                                                        {Loading ? (
                                                            <Spinner />
                                                        ) : (
                                                            <Button type="submit" color="success" disabled={Loading ? true : false}>
                                                                Cadastrar
                                                            </Button>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center">
                                    <p>
                                        Voltar para tela de{" "}
                                        <Link to="login" className="font-weight-medium text-primary">
                                            Login
                                        </Link>{" "}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Form>
        </React.Fragment>
    );
};

export default NewGuest;