import React, { useState, useEffect } from "react";
import {
  useNavigate, useParams,
} from 'react-router-dom';
import api from "../../../../services/api";
import {
  Row,
  Col,
  Container,
  Button,
  FormGroup,
  Label,
  Input
} from "reactstrap";

import LayoutHeader from "../../../../components/header";
import LayoutBannerHeader from "../../../../components/bannerHeader";

const ClassesView = props => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [RowData, setRowData] = useState([]);
  const [Loading, setLoading] = useState(true);

  const LoadData = async => {
    setRowData([]);
    api.get('/api/classes/show/' + id).then(response => {
      return response.data
    }).then(data => {
      if (data.success === true) {
        setRowData(data.data);
        setLoading(false);
      }
    }).catch(error => {
      // console.log(error);
    })
  };

  useEffect(() => {
    LoadData();
  }, []);

  return (
    <React.Fragment>

      <LayoutHeader isAdmin={false} show={true} />

      <Container>
        <Row>

          <LayoutBannerHeader show={true} />

          <Col md={12} className="perfil-box">
            <Row className="mb-5">

              <Col md={12} className="content-box">
                <Row>
                  <Col md={12} className="text-center">
                    <div className="titulo-hr">
                      <span>Aula</span>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={2} className="content-box"></Col>
              <Col md={8} className="content-box">
                <Row>
                  <Col md={12}>
                    <div className="boxes">
                      <Row>
                        <Col md={12} className="mb-3">
                          <h5>{RowData.name}</h5>
                          <span>{RowData.subtitle}</span>
                        </Col>
                        <Col md={12}>
                          <iframe width="100%" height="460" src={"https://www.youtube.com/embed/" + RowData.yotube_url} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </Col>
                      </Row>
                    </div>

                    <Row>
                      <Col md={12} className="text-center">
                        <div className="titulo-hr">
                          <span>Descrição</span>
                        </div>
                      </Col>
                      <Col md={12}>
                        <p dangerouslySetInnerHTML={{ __html: RowData.description }}></p>
                      </Col>                      
                    </Row>


                  </Col>
                </Row>
              </Col>
              <Col md={2} className="content-box"></Col>
            </Row>
          </Col>

        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ClassesView;