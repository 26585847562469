import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import {
  Row,
  Col,
  Button,
  Table,
  Spinner,
  FormGroup,
  Input,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import LayoutHeader from "../../../components/header";
import {
  DateToData,
  formatarMilharReais
} from '../../../helpers/mascarasParaCampos';
import ModalInterviewAdminMembersPaymentsChange from "../../admin/members/members/modal/paymentsChange";
import ModalInterviewAdminMembersPaymentsRenegotiate from "../../admin/members/members/modal/paymentsRenegotiate";
import ModalInterviewAdminMembersPaymentsView from "../../admin/members/members/modal/paymentsView";
import SwalDeleteItem from "../../../helpers/swal";

const MembersLeads = props => {

  let NameModule = 'Leads';

  document.title = NameModule + " | Empresários SA";

  const [Loading, setLoading] = useState(true);
  const [RowData, setRowData] = useState([]);

  const [Pages, setPages] = useState([]);
  const [PaginationTotalItems, setPaginationTotalItems] = useState(1);
  const [PaginationPageActual, setPaginationPageActual] = useState(1);

  var month = new Date().getMonth() + 1;
  if (month < 10) {
    month = '0' + month;
  }
  var year = new Date().getFullYear();

  const [FormData, setFormData] = useState({
    "competence": year + '-' + month
  });

  function LoadData(Page) {

    setLoading(true);
    setRowData([]);

    try {
      const Data = {
        "competence": FormData.competence || '',
        "origin": FormData.origin || '',
        "search": FormData.search || '',
      }
      setPaginationPageActual(Page);
      api.post('/api/leads/filter?page=' + Page, Data).then(response => {
        return response;
      }).then(data => {
        setRowData(data.data);
        setPaginationTotalItems(data.data.total);
        listItems(data.data.total, data.data.per_page);
        setLoading(false);
      }).catch(error => {
        // console.log(error);
      });
    } catch (error) {
      // console.log(error);
    }
  }

  useEffect(() => {
    LoadData(1);
  }, []);

  function listItems(total, PorPage) {
    let totalPages = 1;
    let asdasdsa = [];
    if (PorPage < total) {
      totalPages = total / PorPage;
    }
    for (let a = 1; a < totalPages + 1; a++) {
      asdasdsa.push([a]);
    }
    setPages(asdasdsa);
  }

  function PaginationOpen(Page) {
    LoadData(Page);
  }

  return (
    <React.Fragment>

      <LayoutHeader isAdmin={false} show={true} />

      <div className="box-container">
        <Row className="pt-3910201290">

          <Col md={12} className="content-box">
            <Row>
              <Col md={12} className="text-center">
                <div className="titulo-hr">
                  <span>{NameModule}</span>
                </div>
              </Col>

              <Col lg={12}>
                <Row>
                  <Col lg={2}>
                    <FormGroup floating>
                      <Input
                        id="competence"
                        className="competence"
                        name="competence"
                        type="month"
                        onChange={(event) => { setFormData({ ...FormData, 'competence': event.target.value }); }}
                        value={FormData.competence ? FormData.competence : ''}
                      />
                      <Label>
                        Competência
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg={2}>
                    <FormGroup floating>
                      <Input
                        id="origin"
                        className="status"
                        name="origin"
                        type="select"
                        onChange={(event) => {
                          setFormData({ ...FormData, 'origin': event.target.value });
                        }}
                        value={FormData.origin ? FormData.origin : ''}
                      >
                        <option value=""> Todos </option>
                        <option value="0"> Geral </option>
                        <option value="1"> Convidado </option>
                        <option value="2"> Indicação </option>
                        <option value="3"> Negócio </option>
                      </Input>
                      <Label>
                        Origem
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg={7}>
                    <FormGroup floating>
                      <Input
                        id="search"
                        className="search"
                        name="search"
                        type="search"
                        placeholder="Especilidade"
                        onChange={(event) => { setFormData({ ...FormData, 'search': event.target.value }); }}
                        value={FormData.search ? FormData.search : ''}
                      />
                      <Label>
                        Buscar
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg={1} className="text-center">
                    <Button
                      className="btnFilter"
                      onClick={() => {
                        LoadData();
                      }}
                    >
                      Filtrar
                    </Button>
                  </Col>
                </Row>
              </Col>

            </Row>
          </Col>
          <Col md={12} className="content-box">
            <Row className="mb-3">
              <Col md={12}>
                <div className="boxes">
                  <Table responsive size="sm" className="table">
                    <thead>
                      <th className=""> Origem </th>
                      <th className=""> Nome </th>
                      <th className=""> CPF / CNPJ </th> 
                      <th className=""> Celular </th>
                      <th className=""> E-mail </th>
                      <th className=""> Empresa </th>
                      <th className=""> Ramo </th>
                      <th className=""> Negócio </th>
                      <th className=""> Evento </th>
                      <th className=""> Membro Ref. </th>                      
                      <th className=""> ID </th>
                    </thead>
                    <tbody>

                      {Loading ? (
                        <tr>
                          <td colSpan={50} style={{ border: '0px' }}>
                            <div style={{ width: '100%', textAlign: 'center' }}>
                              <Spinner color="info" className="mt-5 mb-2"></Spinner>
                              <br />
                              Carregando dados...
                            </div>
                          </td>
                        </tr>
                      ) : null}

                      {!Loading && RowData.results === 0 ? (
                        <tr>
                          <td colSpan={50} style={{ border: '0px', paddingTop: '30px', textAlign: 'center' }}>
                            Sem dados com esses filtros no momento.
                          </td>
                        </tr>
                      ) : null}

                      {RowData && !Loading ? (
                        RowData.data.map((row, index) => (
                          <tr key={index}>
                            <td>
                              {row.origin == 0 ? 'Geral' : ''}
                              {row.origin == 1 ? 'Convidado' : ''}
                              {row.origin == 2 ? 'Indicação' : ''}
                              {row.origin == 3 ? 'Negócio' : ''}
                            </td>
                            <td>
                              {row.name ? row.name : '-'}
                            </td>
                            <td>
                              {row.cpf ? row.cpf : '-'}
                            </td>                            
                            <td>
                              {row.cell_phone ? row.cell_phone : '-'}
                            </td>
                            <td>
                              {row.email ? row.email : ''}
                            </td>
                            <td>
                              {row.companie ? row.companie : '-'}
                            </td>
                            <td>
                              {row.branches ? row.branches.name : '-'}
                            </td>
                            <td>
                              {row.business ? row.business : '-'}
                            </td>                            
                            <td>
                              {row.events ? row.events.name : '-'}
                            </td>
                            <td>
                              {row.members ? row.members.nickname : '-'}
                            </td>                                                        
                            <td>
                              {row.id ? row.id : '-'}
                            </td>
                          </tr>
                        ))
                      ) : null}

                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col md={9}>
                <div className="TablePagination">
                  Mostrando página <strong>{PaginationPageActual}</strong> de <strong>1</strong>. | Total de registros é de <strong>{PaginationTotalItems}</strong>.
                </div>
              </Col>
              <Col md={3}>
                <FormGroup row className="text-right">
                  <Label
                    for="exampleEmail"
                    sm={4}
                  >
                    Página
                  </Label>
                  <Col sm={8}>
                    <Input
                      className=""
                      autoComplete={false}
                      name="companie_taxation"
                      type="select"
                      placeholder="Tributação"
                      onChange={(event) => { PaginationOpen(event.target.value); }}
                    >
                      {Pages.map((row) => (
                        <option key={row} value={row} > {row} </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default MembersLeads;