import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import {
    Row,
    Col
} from "reactstrap";
import ModalBase from '../../../../../components/modal/modal';
import api from "../../../../../services/api";
import { formatarMilharReais } from '../../../../../helpers/mascarasParaCampos';

export default function ModalInterviewAdminMembersRulesView({
    modalId,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
}) {

    const [Loading, setLoading] = useState(false);

    const [rows, setRows] = useState({});

    const LoadDataShow = async => {
        if (modalId > 0) {
            try {
                api.get('/api/points/show/' + modalId).then(response => {
                    return response;
                }).then(data => {
                    if (data.data.success) {
                        if (data.data.data) {
                            const item = data.data.data;
                            console.log('item', item);
                            setRows(item);
                        }
                        setLoading(false);
                    }
                }).catch(error => {
                    // console.log(error);
                });
            } catch (error) {
                // console.log(error);
            }
        }
    };

    useEffect(() => {
        LoadDataShow();
    }, [modalId]);

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={title}
        >
            <Row>
                <Col sm="12" className="m-0 p-0">
                    <hr className="m-0 p-0" />
                </Col> 
                <Col sm="12" className="mt-3 mb-2">
                    <Col sm="12">
                        <Row>
                            <Col sm="12" className="mb-2">
                                Data cadastro:<br />
                                <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                    {rows.created_at ? rows.created_at : '-'}
                                </div>
                            </Col>                                 
                            <Col sm="12" className="mb-2">
                                Regra:<br />
                                <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                    {rows && rows.rules ? rows.rules.name : '-'}
                                </div>
                            </Col>
                            <Col sm="4" className="mb-2">
                                Checkado:<br />
                                <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                    {rows && rows.checked && rows.checked == 1 ? 'Sim' : 'Não'}
                                </div>
                            </Col>
                            <Col sm="4" className="mb-2">
                                Tipo:<br />
                                <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                    {rows.type ? (
                                        rows.type < 2 ? (<span style={{ color: 'red' }}>Perde</span>) : (<span style={{ color: 'green' }}>Ganha</span>)
                                    ) : '-'}
                                </div>
                            </Col>
                            <Col sm="4" className="mb-2">
                                Pontos:<br />
                                <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                    {rows.value ? (
                                        rows.type < 2 ? (<span style={{ color: 'red' }}>{rows.value}</span>) : (<span style={{ color: 'green' }}>{rows.value}</span>)
                                    ) : '-'}
                                </div>
                            </Col>
                            <Col sm="12" className="mt-4 mb-2">
                                Unidade:<br />
                                <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                    {rows && rows.users && rows.users.units ? rows.users.units.name : '-'}
                                </div>
                            </Col>                            
                            <Col sm="12" className="mb-2">
                                Membro:<br />
                                <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                    {rows.users ? rows.users.nickname : '-'}
                                </div>
                            </Col>    
                            {rows && rows.rules_id < 4 ? (
                                <Col sm="12" className="mb-2">
                                    {rows && rows.rules_id == 1 ? 'Evento' : null}
                                    {rows && rows.rules_id == 2 ? 'Evento' : null}
                                    {rows && rows.rules_id == 3 ? 'Evento' : null}
                                    
                                    <br />
                                    <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                        {rows && rows.rules && rows.rules.id === 1 && rows.rules_presencial && rows.rules_presencial.events ? rows.rules_presencial.events.name : null}
                                        {rows && rows.rules && rows.rules.id === 2 && rows.rules_presencial_interviews && rows.rules_presencial_interviews.intervieweds ? rows.rules_presencial_interviews.intervieweds.name : null}
                                        {rows && rows.rules && rows.rules.id === 3 && rows.rules_presencial_meetings && rows.rules_presencial_meetings.name ? rows.rules_presencial_meetings.name : null}
                                    </div>
                                </Col>
                            ) : null}     
                            {rows && rows.rules_id < 6 && rows.rules_id > 3 ? (                                               
                            <Col sm="12" className="mb-2">
                                {rows && rows.rules_id == 4 ? 'Recomendação' : null}
                                {rows && rows.rules_id == 5 ? 'Recomendação Fechada por' : null}
                                <br />
                                <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                    {rows.users_at ? rows.users_at.name : '-'}
                                </div>
                            </Col>
                            ) : null}    

                            {rows && (rows.rules_id == 6) ? (                                               
                                <> 
                                    <Col sm="12" className="mb-2">
                                        Negócio
                                        <br />
                                        <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                            {rows && rows.rules_business_open && rows.rules_business_open.operation == 1 ? 'Venda' : null}
                                            {rows && rows.rules_business_open && rows.rules_business_open.operation == 2 ? 'Compra' : null}
                                        </div>
                                    </Col> 
                                    <Col sm="12" className="mb-2">
                                        Descrição negócio
                                        <br />
                                        <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                            {rows && rows.rules_business_open ? rows.rules_business_open.description : null}
                                        </div>
                                    </Col>       
                                    <Col sm="12" className="mb-2">
                                        Anexo
                                        <br />
                                        <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                            {rows && rows.rules_business_open && rows.rules_business_open.file ? (
                                                <a href={'https://api.empresariossa.com/storage/files/' + rows.rules_business_open.file} target='_blank' title="" rel="noreferrer">Ver / Download</a>
                                            ) : null}
                                        </div>
                                    </Col>                                                                                                         
                                    <Col sm="12" className="mb-2">
                                        Valor em R$
                                        <br />
                                        <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                            {rows && rows.rules_business_open && rows.rules_business_open ? formatarMilharReais(rows.rules_business_open.amount) : null}
                                        </div>
                                    </Col>                                  
                                    {rows && rows.rules_business_open && rows.rules_business_open.members ? (
                                        <Col sm="12" className="mb-2">
                                            Negócio com membro
                                            <br />
                                            <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                                {rows && rows.rules_business_open && rows.rules_business_open.members ? rows.rules_business_open.members.nickname : null} asdasd
                                            </div>
                                        </Col>                                         
                                    ) : (
                                        <>
                                            <Col sm="12" className="mt-4 mb-2">
                                                Nome
                                                <br />
                                                <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                                    {rows && rows.rules_business_open && rows.rules_business_open.name ? rows.rules_business_open.name : null}
                                                </div>
                                            </Col>    
                                            <Col sm="12" className="mb-2">
                                                CNPJ / CPF
                                                <br />
                                                <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                                    {rows && rows.rules_business_open && rows.rules_business_open.cnpj_cpf ? rows.rules_business_open.cnpj_cpf : null}
                                                </div>
                                            </Col>    
                                            <Col sm="12" className="mb-2">
                                                Celular
                                                <br />
                                                <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                                    {rows && rows.rules_business_open && rows.rules_business_open.cell_phone ? rows.rules_business_open.cell_phone : null}
                                                </div>
                                            </Col>    
                                            <Col sm="12" className="mb-2">
                                                E-mail
                                                <br />
                                                <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                                    {rows && rows.rules_business_open && rows.rules_business_open.email ? rows.rules_business_open.email : null}
                                                </div>
                                            </Col>                                                                                                                                                                             
                                        </>
                                    )}                                                                    
                                </>
                            ) : null}       

                            {rows && (rows.rules_id == 7) ? (                                               
                                <> 
                                    <Col sm="12" className="mb-2">
                                        Negócio
                                        <br />
                                        <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                            {rows && rows.rules_business_close && rows.rules_business_close.operation == 1 ? 'Venda' : null}
                                            {rows && rows.rules_business_close && rows.rules_business_close.operation == 2 ? 'Compra' : null}
                                        </div>
                                    </Col> 
                                    <Col sm="12" className="mb-2">
                                        Descrição negócio
                                        <br />
                                        <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                            {rows && rows.rules_business_close ? rows.rules_business_close.description : null}
                                        </div>
                                    </Col>   
                                    <Col sm="12" className="mb-2">
                                        Anexo
                                        <br />
                                        <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                            {rows && rows.rules_business_close && rows.rules_business_close.file ? (
                                                <a href={'https://api.empresariossa.com/storage/files/' + rows.rules_business_close.file} target='_blank' title="" rel="noreferrer">Ver / Download</a>
                                            ) : null}
                                        </div>
                                    </Col>                                                                                                                
                                    <Col sm="12" className="mb-2">
                                        Valor em R$
                                        <br />
                                        <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                            {rows && rows.rules_business_close ? formatarMilharReais(rows.rules_business_close.amount) : null}
                                        </div>
                                    </Col>                                  
                                    {rows && rows.rules_business_close && rows.rules_business_close.members ? (
                                        <Col sm="12" className="mb-2">
                                            Negócio com membro
                                            <br />
                                            <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                                {rows && rows.rules_business_close && rows.rules_business_close.members ? rows.rules_business_close.members.nickname : null}
                                            </div>
                                        </Col>                                         
                                    ) : (
                                        <>
                                            <Col sm="12" className="mt-4 mb-2">
                                                Nome
                                                <br />
                                                <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                                    {rows && rows.rules_business_close && rows.rules_business_close.name ? rows.rules_business_close.name : null}
                                                </div>
                                            </Col>    
                                            <Col sm="12" className="mb-2">
                                                CNPJ / CPF
                                                <br />
                                                <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                                    {rows && rows.rules_business_close && rows.rules_business_close.cnpj_cpf ? rows.rules_business_close.cnpj_cpf : null}
                                                </div>
                                            </Col>    
                                            <Col sm="12" className="mb-2">
                                                Celular
                                                <br />
                                                <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                                    {rows && rows.rules_business_close && rows.rules_business_close.cell_phone ? rows.rules_business_close.cell_phone : null}
                                                </div>
                                            </Col>    
                                            <Col sm="12" className="mb-2">
                                                E-mail
                                                <br />
                                                <div style={{ fontWeight: '600', width: '100%', background: '#fff', border: '0px', borderRadius: '8px', padding: '4px 10px' }}>
                                                    {rows && rows.rules_business_close && rows.rules_business_close.email ? rows.rules_business_close.email : null}
                                                </div>
                                            </Col>                                                                                                                                                                             
                                        </>
                                    )}                                                                    
                                </>
                            ) : null}      

                        </Row>
                    </Col>
                </Col>
            </Row>
        </ModalBase>
    );
}

ModalInterviewAdminMembersRulesView.propTypes = {
    modalId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
