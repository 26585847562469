import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import {
  Row,
  Col,
  Button,
  Table,
  Spinner,
  FormGroup,
  Input,
  Label,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap";
import LayoutHeader from "../../../components/header";
import ModalInterviewAdminMembersRulesView from "../../admin/members/members/modal/rulesView";

const MembersScoresList = props => {

  let NameModule = 'Lançamento de Pontuação';

  document.title = NameModule + " | Empresários SA";

  const [Loading, setLoading] = useState(true);

  const [RowData, setRowData] = useState([]);

  const [Pages, setPages] = useState([]);
  const [PaginationTotalItems, setPaginationTotalItems] = useState(1);
  const [PaginationPageActual, setPaginationPageActual] = useState(1);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(); 
  const [id, setModalId] = useState();

  var month = new Date().getMonth() + 1;
  if (month < 10) {
    month = '0' + month;
  }
  var year = new Date().getFullYear();

  const [FormData, setFormData] = useState({
    "competence": year + '-' + month
  });
  
  const [rulesPoints, setRulesPoints] = useState([]);

  const [ids, setIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false); 

  const LoadRulesPoints = async => {
    setRulesPoints([]);
    api.get('/api/rules-points/active').then(response => {
      return response.data
    }).then(data => {
      setRulesPoints(data.data);
    }).catch(error => {
      // console.log(error);
    })
  };

  function LoadData(Page) {

    setLoading(true);
    setRowData([]);

    try {
      const Data = {
        "competence": FormData.competence || '',        
        "type": FormData.type || '',
        "search": FormData.search || '',
      }
      setPaginationPageActual(Page);
      api.post('/api/points/filter?page=' + Page, Data).then(response => {
        return response;
      }).then(data => {
        setRowData(data.data);
        setPaginationTotalItems(data.data.total);
        listItems(data.data.total, data.data.per_page);
        setLoading(false);
      }).catch(error => {
        // console.log(error);
      });
    } catch (error) {
      // console.log(error);
    }
  }

  useEffect(() => {
    LoadRulesPoints();
    LoadData(1);
  }, []);

  function listItems(total, PorPage) {
    let totalPages = 1;
    let asdasdsa = [];
    if (PorPage < total) {
      totalPages = total / PorPage;
    }
    for (let a = 1; a < totalPages + 1; a++) {
      asdasdsa.push([a]);
    }
    setPages(asdasdsa);
  }

  function PaginationOpen(Page) {
    LoadData(Page);
  }

  function update(Page) {
    try {
      const Data = {
        "competence": FormData.competence || '',
        "ids": ids || '',
      }
      setPaginationPageActual(Page);
      api.post('/api/points/save', Data).then(response => {
        return response;
      }).then(data => {
        LoadData(PaginationPageActual);
      }).catch(error => {
        // console.log(error);
      });
    } catch (error) {
      // console.log(error);
    }
  }


  function reloadData2() {
    setModalType('');
    setModalOpen(false);
    setModalId(0);
    LoadData(PaginationPageActual);
  }

  return (
    <React.Fragment>

      <LayoutHeader isAdmin={false} show={true} />
      <div className="box-container">
        <Row className="pt-3910201290">

        {modalType === 'view' ? (<ModalInterviewAdminMembersRulesView
          toggle={() => setModalOpen(!modalOpen)}
          isOpen={modalOpen}
          modalId={id}
          size="md"
          title="Ver | Regras"
          reloadData={reloadData2}
        />) : ''}

          <Col md={12} className="content-box">
            <Row>
              <Col md={12} className="text-center">
                <div className="titulo-hr">
                  <span>{NameModule}</span>
                </div>
              </Col>

              <Col lg={12}>
                <Row>
                 <Col lg={2}>
                    <FormGroup floating>
                      <Input
                        id="competence"
                        className="competence"
                        name="competence"
                        type="month"
                        onChange={(event) => { setFormData({ ...FormData, 'competence': event.target.value }); }}
                        value={FormData.competence ? FormData.competence : ''}
                      />
                      <Label>
                        Competência
                      </Label>
                    </FormGroup>
                  </Col>             
                  <Col lg={2}>
                    <FormGroup floating>
                      <Input
                        id="type"
                        className="type"
                        name="type"
                        type="select"
                        onChange={(event) => {
                          setFormData({ ...FormData, 'type': event.target.value });
                        }}
                        value={FormData.type ? FormData.type : ''}
                      >
                        <option value=""> Todos </option>
                        {rulesPoints.map((row, index) => (
                          <option key={index} value={row.id}>{row.name}</option>
                        ))}
                      </Input>
                      <Label>
                        Tipo
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg={7}>
                    <FormGroup floating>
                      <Input
                        id="search"
                        className="search"
                        name="search"
                        type="search"
                        placeholder="Especilidade"
                        onChange={(event) => { setFormData({ ...FormData, 'search': event.target.value }); }}
                        value={FormData.search ? FormData.search : ''}
                      />
                      <Label>
                        Buscar
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg={1} className="text-center">
                    <Button
                      className="btnFilter"
                      onClick={() => {
                        LoadData();
                      }}
                    >
                      Filtrar
                    </Button>
                  </Col>
                </Row>
              </Col>

            </Row>
          </Col>
          <Col md={12} className="content-box">
            <Row className="mb-3">
              <Col md={12}>
                <div className="boxes">
                  <Table responsive size="sm" className="table">
                    <thead>
                      <th> </th>                      
                      <th> Regra </th>
                      <th> </th>
                      <th> Membro </th>
                      <th> Unidade </th>
                      <th> Tipo </th>
                      <th> Ponto(s) </th>
                      <th> Verificado </th>
                      <th> Enviado por </th>
                      <th> Cadastro </th>
                    </thead>
                    <tbody>

                      {Loading ? (
                        <tr>
                          <td colSpan={50} style={{ border: '0px' }}>
                            <div style={{ width: '100%', textAlign: 'center' }}>
                              <Spinner color="info" className="mt-5 mb-2"></Spinner>
                              <br />
                              Carregando dados...
                            </div>
                          </td>
                        </tr>
                      ) : null}

                      {!Loading && RowData.data && RowData.data.length === 0 ? (
                        <tr>
                          <td colSpan={50} style={{ border: '0px', paddingTop: '30px', textAlign: 'center' }}>
                            Sem dados com esses filtros no momento.
                          </td>
                        </tr>
                      ) : null}

                      {RowData && RowData.data && RowData.data.length > 0 && !Loading ? (
                        RowData.data.map((row, index) => (
                          <tr key={index}>
                            <td style={{ width: '10px', textAlign: 'center' }}>
                              <UncontrolledDropdown
                                className="me-2"
                                direction="down"
                              >
                                <DropdownToggle
                                  caret
                                  color="dark"
                                  size="sm"
                                  style={{ padding: '1px 5px' }}
                                >
                                  Acões
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem header>
                                    Selecione uma ação
                                  </DropdownItem>
                                  <DropdownItem onClick={(event) => {
                                    setModalType('view');
                                    setModalOpen(true);
                                    setModalId(row.id);
                                  }}>
                                    Detalhes
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>                                                       
                            <td style={{ verticalAlign: 'middle' }}>
                              {row.rules ? row.rules.name : '-'}
                            </td>
                            <td style={{ verticalAlign: 'middle' }}> 
                              {row.rules && row.rules.id === 1 && row.rules_presencial && row.rules_presencial.events ? row.rules_presencial.events.name : null}
                              {row.rules && row.rules.id === 2 && row.rules_presencial_interviews && row.rules_presencial_interviews.name ? row.rules_presencial_interviews.name : null}
                              {row.rules && row.rules.id === 3 && row.rules_presencial_meetings && row.rules_presencial_meetings.name ? row.rules_presencial_meetings.name : null}
                            </td>                            
                            <td style={{ verticalAlign: 'middle' }}>
                              {row.users ? row.users.nickname : '-'}
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              {row.users && row.users.units ? row.users.units.name : '-'}
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              {row.type ? (
                                row.type < 2 ? (<span style={{ color: 'red' }}>Perde</span>) : (<span style={{ color: 'green' }}>Ganha</span>)
                              ) : '-'}
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              {row.value ? (
                                row.type < 2 ? (<span style={{ color: 'red' }}>{row.value}</span>) : (<span style={{ color: 'green' }}>{row.value}</span>)
                              ) : '-'}
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              {row.checked == 1 ? 'Foi verificado' : 'Ainda não'}
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              {row.users_at ? row.users_at.name : '-'}
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              {row.created_at ? row.created_at : '-'}
                            </td>
                          </tr>
                        ))
                      ) : null}

                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col md={9}>
                <div className="TablePagination">
                  Mostrando página <strong>{PaginationPageActual}</strong> de <strong>1</strong>. | Total de registros é de <strong>{PaginationTotalItems}</strong>.
                </div>
              </Col>
              <Col md={3}>
                <FormGroup row className="text-right">
                  <Label
                    for="exampleEmail"
                    sm={4}
                  >
                    Página
                  </Label>
                  <Col sm={8}>
                    <Input
                      className=""
                      autoComplete={false}
                      name="companie_taxation"
                      type="select"
                      placeholder="Tributação"
                      onChange={(event) => { PaginationOpen(event.target.value); }}
                    >
                      {Pages.map((row) => (
                        <option key={row} value={row} > {row} </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default MembersScoresList;