import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Spinner,
} from "reactstrap";
import ModalBase from '../../../../../components/modal/modal';
import api from "../../../../../services/api";
import ModalAddAdminMembersForm from '../../members/modal/form';

export default function ModalInterviewAdminMembersView({
    modalId,
    membersId,
    reloadData,
    toggle,
    isOpen,
    size,
    title,
}) {

    const [FormData, setFormData] = useState({});
    const [Logs, setLogs] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [Id, setId] = useState();
    const [Status, setStatus] = useState();

    function LoadLogs() {
        setLoading(true);
        setLogs([]);
        if (modalId) {
            api.get('/api/interviews/show/' + modalId).then(response => {
                return response.data
            }).then(data => {
                // console.log('interviews_members', data.data.interviews_members);
                setLogs(data.data.logs);
                if (data.data) {
                    setId(data.data.interviewed);
                    setStatus(data.data.status);
                    setFormData(data.data);
                }
                setLoading(false);
            }).catch(error => {
                // console.log(error);
            })
        }
        if (membersId) {
            api.get('/api/interviews/show/users/' + membersId).then(response => {
                return response.data
            }).then(data => {
                // console.log('interviews_members', data.data.interviews_members);
                setLogs(data.data.logs);
                if (data.data) {
                    setId(data.data.interviewed);
                    setStatus(data.data.status);
                    setFormData(data.data);
                }
                setLoading(false);
            }).catch(error => {
                // console.log(error);
            })
        }        
    };

    useEffect(() => {
        LoadLogs();
    }, [modalId, membersId]);

    return (
        <ModalBase
            toggle={toggle}
            isOpen={isOpen}
            size={size}
            centered={true}
            title={'Entrevistar | ' + title}
        >
            <Row>
                <Col sm="12">
                    {Status == 4 ? (
                        <Row className="mb-5">
                            <Col sm="12">
                                <h4 className="pb-3">Dados da entrevista</h4>
                                <div style={{ width: '100%', padding: '20px 20px 8px 20px', borderRadius: '8px', background: '#fff' }}>
                                    <Row>
                                        <Col sm="6">
                                            <Row>
                                                <Col sm="12" className="mb-2" style={{ fontSize: '15px', color: '#888' }}>
                                                    Como você iniciou sua carreira e como chegou até sua posição atual?
                                                </Col>
                                                <Col sm="12">
                                                    <p dangerouslySetInnerHTML={{ __html: FormData.question_1 }}></p>
                                                </Col>
                                                <Col sm="12" className="mb-2" style={{ fontSize: '15px', color: '#888' }}>
                                                    Quais parcerias você busca hoje?
                                                </Col>
                                                <Col sm="12">
                                                    <p dangerouslySetInnerHTML={{ __html: FormData.question_2 }}></p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm="6">
                                            <Row>
                                                <Col sm="12" className="mb-2" style={{ fontSize: '15px', color: '#888' }}>
                                                    Quais membros podem fazer sentido fazer uma aproximação com este novo membro?
                                                </Col>
                                                <Col sm="12">
                                                    {FormData.interviews_members && !Loading ? (
                                                        FormData.interviews_members.map((row, index) => (
                                                            <span key={index}>
                                                                {index+1 < FormData.interviews_members.length ? (
                                                                    row.users ? row.users.nickname + ', ' : null
                                                                ) : (
                                                                    row.users ? row.users.nickname : null
                                                                )}
                                                            </span>
                                                        ))
                                                    ) : (
                                                        'Nenhum membro selecionado.'
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>

                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    ) : null}
                    <Row>
                        <Col sm="8">
                            <h4 className="pb-3">Dados do membro</h4>
                            <ModalAddAdminMembersForm
                                modalId={Id}
                                reloadData={reloadData}
                                blockInputs={true}
                                toggle2={toggle}
                            />
                        </Col>
                        <Col sm="4">
                            <h4 className="pb-3">Histórico da entrevista</h4>
                            <div style={{ width: '100%', height: '100vmax', padding: '20px', borderRadius: '8px', background: '#fff', overflowX: 'hidden', overflowY: 'scroll' }}>
                                <Row>
                                    {FormData.logs && !Loading ? (
                                        FormData.logs.map((row, index) => (
                                            <Col sm="12" key={index}>
                                                <Row>
                                                    <Col sm="12" className="mb-2" style={{ fontSize: '11px', color: '#888' }}>
                                                        {row.created_at}
                                                    </Col>
                                                    <Col sm="12">
                                                        <p dangerouslySetInnerHTML={{ __html: row.note }}></p>
                                                    </Col>
                                                    <Col sm="12" className="mb-3">
                                                        <hr style={{ borderTop: '1px dashed #999' }} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        ))
                                    ) : (
                                        <Col sm="12" className="text-center mt-5">
                                            <Spinner></Spinner>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="mt-3 mb-2">
                <Col sm="12" className="text-right pb-2">
                    <button type="button" className="btn btn-secondary" onClick={toggle} disabled={Loading ? true : false}>Fechar</button>
                </Col>
            </Row>
        </ModalBase>
    );
}

ModalInterviewAdminMembersView.propTypes = {
    modalId: PropTypes.number,
    membersId: PropTypes.number,
    reloadData: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
}
